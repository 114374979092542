import React from 'react'
import PropTypes from 'prop-types'

import Link from '../Link'

import { handleProductClick } from '../../utils/helpers'

const ProductSoon = ({
  className,
  title,
  slug
}) => (
  <div
    className={`col-6 content-box product-container ${className}`}
    style={{ maxWidth: 574 }}
    onClick={e => handleProductClick(e, slug)}
    role="button"
    tabIndex={0}
  >
    <div className="product soon">
      <div className="product-bg" />

      <div className="product-soon">
        <img
          // @ts-ignore
          src={require('../../images/categories/coming-soon/coming-soon-small.svg')}
          alt="Coming soon"
        />
      </div>

      <div className="product-title">{ title }</div>

      <div className="product-image">
        <img
          // @ts-ignore
          src={require('../../images/categories/coming-soon/overview-coming-soon@2x.png')}
          className="soon"
          alt="Coming soon placeholder"
        />
      </div>

      <div className="product-action-bar">
        <Link className="text-link coming-soon violet" to={`/categories/${slug}`}>View</Link>
      </div>
    </div>
  </div>
)

ProductSoon.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
}

export default ProductSoon
