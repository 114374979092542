import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionReplace from 'react-css-transition-replace'
import { graphql } from 'gatsby'

import withContext from '../../lib/withContext'
import client from '../../shopify'

import Examples from '../../components/Examples'
import Products from '../../components/Shopify/Products'
import ProductsSingle from '../../components/Shopify/ProductsSingle'
import SEO from '../../components/SEO'
import TopSellingCards from '../../components/TopSellingCards'
import GeneralUsps from '../../components/GeneralUsps'
import DealCTA from '../../components/DealCTA'

class Categories extends Component {
  constructor(props) {
    super(props)

    this.state = {
      products: [],
      currentView: 'sets',
    }
  }

  componentDidMount() {
    client.checkout.create()

    // @ts-ignore
    client.collection.fetchAllWithProducts({ first: 250, productsFirst: 250 })
      .then((collections) => {
        const sets = collections.find(collection => collection.handle === 'icon-sets')

        function sortOnUpdated(a, b) {
          if (a.updatedAt > b.updatedAt) {
            return -1
          } else if (a.updatedAt < b.updatedAt) {
            return 1
          }
          return 0
        }

        const products = sets.products.sort(sortOnUpdated)

        this.setState({ products })
      })

    client.shop.fetchInfo()
  }

  toggleIconView = (currentView) => {
    this.setState(() => ({ currentView }))
  }

  render() {
    const { products, currentView } = this.state

    const {
      addVariantToCart,
      lineItems,
      toggleModalShow,
      fullCollectionId,
      data: contentfulData
    } = this.props

    const metaData = {
      title: 'Categories',
      image: ''
    }

    const topCardData = [
      {
        colorType: 'rose',
        slug: 'ecommerce',
        title: 'Ecommerce',
        comingSoon: false
      },
      {
        colorType: 'sun',
        slug: 'ui-1',
        title: 'UI 1',
        comingSoon: false
      },
      {
        colorType: 'sea',
        slug: 'ui-2',
        title: 'UI 2',
        comingSoon: false
      }
    ]

    return (
      <div>
        <SEO metaData={metaData} />
        <main className="overview animated fadeInPage">
          <section className="icons padding-xl-bottom">
            <TopSellingCards
              {...{
                topCardData,
                lineItems,
                products
              }}
            />

            <div className="container-md">
              <ReactCSSTransitionReplace transitionName="fade-wait">
                {
                  currentView === 'sets' ? (
                    <Products
                      {...{
                        products,
                        client,
                        addVariantToCart,
                        lineItems,
                        fullCollectionId,
                        toggleModalShow,
                        contentfulData
                      }}
                      key="1"
                    />
                  ) : <ProductsSingle key="2" />
                }
              </ReactCSSTransitionReplace>
            </div>
          </section>

          <section className="padding-xxl-bottom">
            <GeneralUsps />
          </section>

          <section>
            <div className="container-md">
              <div className="grid yg center text-center">
                <div className="col-12">
                  <h2>Suitable for any platform</h2>
                  <p className="wide margin-l-bottom">Because of their size Jollycons icons easily blend in on any platform. Whether it’s an iOS app or Android app or a webapp. You can use the icons for both category indicators or in action buttons.</p>
                </div>
              </div>
            </div>
            <Examples />
          </section>

          <DealCTA
            type="dark"
            addVariantToCart={addVariantToCart}
            fullCollectionId={fullCollectionId}
          />
        </main>
      </div>
    )
  }
}

Categories.propTypes = {
  addVariantToCart: PropTypes.func,
  contentfulData: PropTypes.any,
  fullCollectionId: PropTypes.string,
  lineItems: PropTypes.array,
  toggleModalShow: PropTypes.func,
  data: PropTypes.any
}

export default withContext(Categories)

export const iconSetQuery = graphql`
  query getIconSets {
    allContentfulIconSet(filter: {
      comingSoon: {
        eq: true
      }
    }) {
      edges {
        node {
          id
          metaTitle
          metaDescription
          slug
          title
          price
          comingSoon
          description {
            id
            description
          }
          relatedSets {
            id
            title
            price
            slug,
            comingSoon
          }
          relatedSetsColors
        }
      }
    }
  }
`
