import React from 'react'
import PropTypes from 'prop-types'

// Components
import Product from './Product'
import ProductSoon from './ProductSoon'
import DiscountDeal from '../DiscountDeal'

class Products extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firstHalf: [],
      loading: true
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { products } = this.props
      const totalAmount = products.length

      if (totalAmount >= 10) {
        const roundUp = num => (num % 2 !== 0 ? num + 1 : num)
        const roundedHalfAmount = Math.round(roundUp(Math.ceil(totalAmount / 2)))
        const firstHalf = products.slice(0, roundedHalfAmount)

        this.setState({
          firstHalf,
          loading: false
        })
      }
    }
  }

  render() {
    const {
      products,
      addVariantToCart,
      lineItems,
      fullCollectionId,
      contentfulData
    } = this.props

    const {
      firstHalf,
      loading
    } = this.state

    return (
      <div>
        {
          loading && (
            <div className="loading-indicator">
              <div className="spinner" />
            </div>
          )
        }

        {
          !loading && firstHalf.length !== 0 ? (
            <div className="grid">
              {
                products.map((product, idx) => (
                  (idx + 1) % 7 === 0 ? (
                    <DiscountDeal
                      fullCollectionId={fullCollectionId}
                      addVariantToCart={addVariantToCart}
                      type="large"
                      isCenter={(idx + 1) % 14 === 0}
                      key={`discount-deal-${idx}`}
                    />
                  ) : (
                    <Product
                      addVariantToCart={addVariantToCart}
                      key={idx}
                      product={product}
                      className="col-6 content-box product-container"
                      lineItems={lineItems.map(({ title }) => title)}
                    />
                  )
                ))
              }

              {
                contentfulData
                && contentfulData.allContentfulIconSet
                && Array.isArray(contentfulData.allContentfulIconSet.edges) && (
                  contentfulData.allContentfulIconSet.edges.map(({ node }) => (
                    <ProductSoon key={`soon-${node.id}`} {...node} />
                  ))
                )
              }

            </div>
          ) : (
            <div className="grid">
              {
                products.map(product => (
                  <Product
                    addVariantToCart={addVariantToCart}
                    key={product.id.toString()}
                    product={product}
                    className="col-6 content-box product-container"
                    lineItems={lineItems.map(({ title }) => title)}
                  />
                ))
              }
            </div>
          )
        }
      </div>
    )
  }
}

Products.propTypes = {
  products: PropTypes.array,
  addVariantToCart: PropTypes.func,
  lineItems: PropTypes.array,
  fullCollectionId: PropTypes.string,
  contentfulData: PropTypes.object
}

export default Products
