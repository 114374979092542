import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import Link from '../Link'

import { handleProductClick } from '../../utils/helpers'

class Product extends Component {
  constructor(props) {
    super(props)

    const defaultOptionValues = {}
    const { product } = this.props
    product.options.forEach((selector) => {
      defaultOptionValues[selector.name] = selector.values[0].value
    })
  }

  render() {
    const {
      className,
      addVariantToCart,
      product: {
        variants,
        title
      },
      lineItems
    } = this.props
    const variant = variants[0]
    const slug = title.replace(/\s+/g, '-').toLowerCase()

    return (
      <div
        className={className}
        style={{ maxWidth: 574 }}
        onClick={e => handleProductClick(e, slug, '.btn')}
        role="button"
        tabIndex={0}
      >
        <div className="product">
          <div className="product-bg" />
          <div className="product-title">{ title }</div>

          <div className="product-image">
            <img
              src={require(`../../images/categories/${slug}/overview-${slug}@2x.png`)}
              alt={`${title} product shot`}
            />
          </div>

          <div className="product-action-bar">
            <Link className="text-link violet" to={`/categories/${slug}`}>View</Link>
            <Button
              {...{
                text: 'Buy Category',
                notInBasket: lineItems.includes(title),
                addVariantToCart,
                product: variant,
                isDark: false,
                variant: 'quaternary'
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

Product.propTypes = {
  className: PropTypes.string,
  addVariantToCart: PropTypes.func,
  product: PropTypes.shape({
    variants: PropTypes.array,
    title: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ])
  }),
  lineItems: PropTypes.array
}

export default Product
