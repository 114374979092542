import React from 'react'

import ProductSingle from './ProductSingle'
import icons from '../../icons.json'

const ProductsSingle = () => (
  <div className="products-single">
    {
      icons.map((product, idx) => (
        <ProductSingle name={product.name} set={product.set} key={idx} />
      ))
    }
  </div>
)

export default ProductsSingle
