import React from 'react'

import {
  audio,
  food,
  weather,
  imageEditing,
  devices,
  sports
} from '../images/home'

const Examples = () => (
  <div className="container-lg examples">
    <div className="examples-row">
      <div className="example small leaf">
        <img src={audio} alt="Audio Example" />
      </div>
      <div className="example sea-second">
        <img src={sports} alt="Food Example" />
      </div>
    </div>

    <div className="examples-row">
      <div className="example sun">
        <img src={weather} alt="Weather Example" />
      </div>
      <div className="example small rose">
        <img src={imageEditing} alt="Graphic Editing Example" />
      </div>
    </div>

    <div className="examples-row">
      <div className="example small violet">
        <img src={devices} alt="Devices Example" />
      </div>
      <div className="example sea">
        <img src={food} alt="Food Example" />
      </div>
    </div>
  </div>
)

export default Examples
